import React from 'react'
import { connect } from 'react-redux'

import Login from './views/login'
import ManagerView from './views/manager'

import User from './models/user'

import {
  AppState,
  setLoginData,
} from './store/store'

interface MainStateProps {
  user: User
}

interface MainDispatchProps {
  onLogin(user: User): void,
  onLogout(): void
}

interface MainOwnProps {
}

type MainProps = MainStateProps & MainDispatchProps & MainOwnProps

const mapStateToProps = (state: AppState, ownProps: MainOwnProps) : MainStateProps => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch: Function, ownProps: MainOwnProps) : MainDispatchProps => {
  return {
    onLogin: (user: User) => {
      dispatch(setLoginData(user))
    },
    onLogout: () => {
      dispatch(setLoginData(new User({})))
    }
  }
}

export const Main: React.SFC<MainProps> = props => {
  const renderView = () => {
    if (!props.user.exists) {
      return <Login onLogin={props.onLogin}/>
    }

    return <ManagerView
      user={props.user}
      onLogout={props.onLogout}
    />
  }

  return <div className="gargo">
    { renderView() }
  </div>
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
