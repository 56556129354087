import React from 'react'
import { Button, Classes, Dialog, InputGroup, TextArea, FormGroup } from '@blueprintjs/core'
import { axios, t } from '../common'
import AppToaster from './toaster'
import SalesOrder from '../models/sales_order'
import User from '../models/user'

interface Props {
  user: User
  salesOrder: SalesOrder
  show: boolean
  onClose: () => void
}

interface State {
  email: string
  question: string
}

export default class OrderQuestion extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      email: props.user.email,
      question: ''
    }
    this.send = this.send.bind(this)
  }

  componentWillReceiveProps(props: Props) {
    this.setState({ question: '', email: props.user.email })
  }

  async send () {
    try {
      await axios.post('/questions', {
        ...this.state,
        order: this.props.salesOrder.code,
        user: this.props.user
      })
      AppToaster.show({ message: "Success", intent: 'success' })
      this.props.onClose()
    } catch (e) {
      console.log('e', e)
      AppToaster.show({ message: "Error", intent: 'danger' })
    }
  }

  render() {
    return <Dialog
      isOpen={this.props.show}
      onClose={this.props.onClose}
      title={`${t('orders.ask_question')}`}>
        <div className={Classes.DIALOG_HEADER}>
          { t('orders.order') }: { this.props.salesOrder.code }
        </div>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={t('users.email')}>
            <InputGroup
              value={this.state.email}
              style={{ width: '100%' }}
              onChange={(e: any) => this.setState({ email: e.target.value })}
            />
          </FormGroup>
          <FormGroup label={t('orders.ask_question')}>
            <TextArea
              value={this.state.question}
              style={{ width: '100%', minHeight: '200px' }}
              onChange={(e: any) => this.setState({ question: e.target.value })}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button icon="cross" text="Close" style={{ marginRight: '10px' }} onClick={this.props.onClose}/>
            <Button icon="envelope" text="Send" intent="success" onClick={this.send} />
          </div>
        </div>
      </Dialog>
  }
}