import React from 'react'
import { Button, Colors, Divider, HTMLTable, Tag, Spinner } from '@blueprintjs/core'
import SalesOrder from '../models/sales_order'
import OrderQuestion from '../components/order_question'
import { formatDateTime, t } from '../common'
import User from '../models/user'

interface Props {
  item: SalesOrder
  axis: string
  align: boolean
  user: User
}

interface State {
  form: SalesOrder
  showQuestion: boolean
}

export default class OrderDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      form: Object.assign({}, props.item),
      showQuestion: false
    }
    this.askQuestion = this.askQuestion.bind(this)
  }

  componentWillReceiveProps(props: Props) {
    this.setState({
      form: props.item
    })
  }

  getSummary() {
    let value = 0
    this.state.form.salesOrder.OrderRows.SalesOrderRow.forEach((item: any) => {
      value += parseFloat(item.Row_Total)
    })
    return value.toFixed(2)
  }

  askQuestion() {
    this.setState({
      showQuestion: true
    })
  }

  renderItems() {
    if (!this.state.form.salesOrder || !this.state.form.salesOrder.OrderRows || !this.state.form.salesOrder.OrderRows.SalesOrderRow) {
      return <h2>No product</h2>
    }

    const rows = this.state.form.salesOrder.OrderRows.SalesOrderRow.map((item: any, index: number) =>
      <tr key={`order-${index}`} style={{backgroundColor: Colors.WHITE}}>
        <td>{ typeof item.Row_productcode === 'string' ? item.Row_productcode : '' }</td>
        <td>{ typeof item.Row_productdescription === 'string' ? item.Row_productdescription : '' }</td>
        <td style={{textAlign: 'right'}}>{ item.Row_amount }</td>
        <td>{ typeof item.Row_unit === 'string' ? item.Row_unit : '' }</td>
        <td style={{textAlign: 'right'}}>{ parseFloat(item.Row_Unitprice).toFixed(2) }</td>
        <td style={{textAlign: 'right'}}>{ parseFloat(item.Row_Total).toFixed(2)}</td>
      </tr>
    )

    return <HTMLTable condensed interactive className="table-wide">
      <thead>
        <tr>
          <th className="w-200">{t('orders.code')}</th>
          <th>{t('orders.product')}</th>
          <th className="w-90 align-right">{t('orders.quantity')}</th>
          <th className="w-90">{t('orders.unit')}</th>
          <th className="w-130 align-right">{t('orders.unit_price')}</th>
          <th className="w-130 align-right">{t('orders.line_price')}</th>
        </tr>
      </thead>
      <tbody>
        { rows }
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={4}></th>
          <th style={{textAlign: 'right', backgroundColor: Colors.LIGHT_GRAY2}}>{t('orders.total')}</th>
          <th style={{marginBottom: '20px', textAlign: 'right', backgroundColor: Colors.LIGHT_GRAY2}}>{ this.getSummary() } €</th>
        </tr>
      </tfoot>
    </HTMLTable>
  }

  render() {
    const height = this.props.align ? (this.props.axis === 'column' ? 'calc(100vh - 100px)' : 'calc(50vh - 50px)') : ''
    const className = `column flex-1 w-100p`
    const style = { backgroundColor: Colors.LIGHT_GRAY4, height }
    const headerStyle : any = { color: this.props.item.exists ? Colors.DARK_GRAY1 : Colors.LIGHT_GRAY1 }

    if (!this.props.item.exists) {
      return <div id="order-detail-view" className={className} style={style}>
        <h1 className="w-100p" style={{...headerStyle, textAlign: 'center'}}>
        { t('orders.select_order') }
        </h1>
      </div>
    }

    if (this.props.item.code.length === 0) {
      return <div id="order-detail-view" className={className} style={style}>
        <h1 className="w-100p" style={{...headerStyle, textAlign: 'center', color: Colors.LIGHT_GRAY1 }}>
          { t('orders.please_wait_processing') }
        </h1>
        <div style={{margin: '20px auto'}}><Spinner size={150} /></div>
      </div>
    }

    return <div id="order-detail-view" className={className} style={style}>
      <h1 className="w-100p" style={headerStyle}>
        <div style={{display: 'flex', flexDirection:'row'}}>
          <div style={{flex: 4}}>
            {t('orders.order')} #{ this.props.item.code }
          </div>
          <div style={{flex: 1}} className="align-right">
            <Button
              icon="help"
              intent="primary"
              onClick={this.askQuestion}
              text={t('orders.ask_question')}
            />
          </div>
        </div>
      </h1>
      <Divider style={{width: '100%'}} />
      <div className="order-details row w-100p" style={{padding:'10px', textTransform: 'uppercase'}}>
        <div className="column w-200">
          {t('orders.created_at')}: <strong>{ formatDateTime(this.props.item.createdAt) }</strong>
        </div>
        <div className="column w-150">
          {t('orders.updated_at')}: <strong>{ formatDateTime(this.props.item.updatedAt) }</strong>
        </div>
        <div className="column w-150">
          {t('orders.state')}: <Tag intent={SalesOrder.getStateIntent(this.props.item.state) as any}><strong>{ SalesOrder.getStateString(this.props.item.state).toUpperCase() }</strong></Tag>
        </div>
        <div className="column m-r-20">
          {t('orders.reference')}: <strong>{this.props.item.reference}</strong>
        </div>
        <div className="column">
          {t('orders.note')}: <strong>{this.props.item.note}</strong>
        </div>
      </div>
      <Divider style={{width: '100%'}} />
      { this.renderItems() }
      <OrderQuestion
        user={this.props.user}
        show={this.state.showQuestion}
        salesOrder={this.props.item}
        onClose={() => {
          this.setState({ showQuestion: false })
        }}
      />
    </div>
  }
}