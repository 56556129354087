import React from 'react'
import { HTMLTable, Button, InputGroup, Tag, Icon, ProgressBar, Colors, Spinner, Intent } from '@blueprintjs/core'
import { EventBusProps, withEvents } from 'react-event-bus'
import { axios, formatDate, formatDateTime, t } from '../common'
import SalesOrder from '../models/sales_order'
import Customer from '../models/client'
import OrderDetail from '../components/order_detail'
import OrderDialog from '../components/order_dialog'

enum Layout {
  Horizontal,
  Vertical
}

interface Props {
  user: any
  EventBus: EventBusProps
}

interface State {
  salesOrders: Array<SalesOrder>
  selected: SalesOrder
  showDialog: boolean
  query: string
  isLoading: boolean
  layout: Layout,
  primaryAxis: string,
  secondaryAxis: string
}

class Clients extends React.Component<Props, State> {
  processingTimeout: any

  constructor(props : Props) {
    super(props)
    this.state = {
      salesOrders: [],
      selected: new SalesOrder({}),
      showDialog: false,
      query: '',
      isLoading: false,
      layout: Layout.Vertical,
      primaryAxis: 'column',
      secondaryAxis: 'row'
    }
    this.onDialogClose = this.onDialogClose.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  componentDidMount () {
    this.fetch()
  }

  componentWillUnmount() {
    if (this.processingTimeout) {
      clearTimeout(this.processingTimeout)
    }
  }

  async fetch() {
    await this.setState({ isLoading: true })
    const query = encodeURI(this.state.query)
    const response = await axios.get(`/sales_orders?query=${query}`)
    await this.setState({
      salesOrders: response.data.salesOrders.map((c: any) => new SalesOrder(c))
    })
    await this.setState({ isLoading: false })

    if (this.isProcessingOrder()) {
      if (!this.processingTimeout) {
        clearTimeout(this.processingTimeout)
      }

      this.processingTimeout = setTimeout(() => {
        this.fetch()
      }, 3000)
    }
  }

  async onDialogClose (data: any, apply: boolean) {
    if (apply) {
      await this.setState({ isLoading: true })
      await axios.post('/sales_orders', data)
    }

    await this.fetch()
    this.setState({ showDialog: false })
  }

  isProcessingOrder() {
    const processing = this.state.salesOrders.find(s => s.code.length === 0 || (typeof s.salesOrder.OrderRows === 'undefined' || typeof s.salesOrder.OrderRows.SalesOrderRow === 'undefined'))
    return typeof processing !== 'undefined'
  }

  async onSelect (client:any, event: MouseEvent) {
    await this.setState({
      selected: client
    })
  }

  async onSave(data: any) {
    await this.setState({ isLoading: true })
    await axios.put(`/clients/${data.id}`, data)
    await this.fetch()
  }

  async addOrder() {
    this.setState({ showDialog: true })
  }

  toggleLayout() {
    if (this.state.layout === Layout.Horizontal) {
      this.setState({
        layout: Layout.Vertical,
        primaryAxis: 'column',
        secondaryAxis: 'row'
      })
    } else {
      this.setState({
        layout: Layout.Horizontal,
        primaryAxis: 'row',
        secondaryAxis: 'column'
      })
    }
  }

  getEmailTag(email: string) {
    return email.length > 1 ?
      <Tag intent="primary" interactive round minimal icon="envelope" className="m-r-10"><a href={`mailto:${email}`}>{email}</a></Tag>
      : null
  }

  getAddress(client: Customer) {
    return [
      client.address,
      client.zipCode,
      client.city
    ].filter(f => typeof f === 'string' && f.length > 0).join(', ')
  }

  renderProgressBar() {
    return this.state.isLoading ? <div className="progress-bar" style={{width: '100%', height: '8px'}}>
      <ProgressBar
        value={1}
        intent="primary"
        animate={true}
        stripes={true}
      />
    </div> : null
  }

  renderRows () {
    return this.state.salesOrders.map((salesOrder, index) => {
      const code = salesOrder.code.length > 0 ?
        <span>{ salesOrder.code }</span>
        :
        <Spinner size={20} intent={Intent.PRIMARY}/>

      return <tr
        className={ this.state.selected && this.state.selected.id === salesOrder.id ? 'row-selected' : ''}
        onClick={(event: any) => { this.onSelect(salesOrder, event) }}
        key={`clients-row-${index}`}>
        <td>{ code }</td>
        <td>{ salesOrder.reference }</td>
        <td><Tag intent={(SalesOrder.getStateIntent(salesOrder.state) as any)}><strong>{ SalesOrder.getStateString(salesOrder.state).toUpperCase() }</strong></Tag></td>
        <td>{ formatDateTime(salesOrder.createdAt) }</td>
        <td>{ salesOrder.deliveryDate ? formatDate(salesOrder.deliveryDate) : '' }</td>
        <td className="align-right">{ salesOrder.total.toFixed(2) } €</td>
        <td className="align-right">{ salesOrder.weight.toFixed(1) } kg</td>
        <td>{ salesOrder.note }</td>
      </tr>
    })
  }

  render () {
    const height = this.state.primaryAxis === 'row' ? 'calc(100vh - 100px)' : 'calc(50vh - 50px)'
    const border = `1px solid ${Colors.LIGHT_GRAY1}`

    return <div className="clients-page column">
      <h1 className="bp3-heading heading">
        <div className="row">
          <div className="column m-r-20">
            { t('pages.orders') }
          </div>
          <div className="column m-r-20">
            <Button icon="refresh" onClick={() => { this.fetch() }} className="m-t-4"/>
          </div>
          <div className="column m-r-20">
            <InputGroup
              rightElement={<Icon className="filter-decor" icon="search"/>}
              placeholder={t('search')}
              onChange={async (event: any) => {
                await this.setState({ query: event.target.value })
                await this.fetch()
              }}
              value={this.state.query}
            />
          </div>
          <div className="actions" style={{ flex: 1, marginTop: 0, paddingTop: 0 }}>
            <Button onClick={(event: any) => { this.toggleLayout() }} icon="resolve" intent="none" className="m-r-20"/>
            <Button onClick={(event: any) => { this.addOrder() }} icon="plus" text={t('orders.new_order')} intent="success"/>
          </div>
        </div>
      </h1>
      { this.renderProgressBar() }
      <div className={`${this.state.primaryAxis} w-100p`}>
        <div className={`${this.state.secondaryAxis} flex-1 w-100p`} style={{ borderRight: border, borderBottom: border, height}}>
          <div className="client-table w-100p" style={{flex: 1, overflowY: 'scroll'}}>
            <HTMLTable condensed interactive className="table-wide">
              <thead>
                <tr>
                  <th className="w-130">{t('orders.order_number')}</th>
                  <th className="w-150">{t('orders.reference')}</th>
                  <th className="w-130">{t('orders.state')}</th>
                  <th className="w-150">{t('orders.created_at')}</th>
                  <th className="w-130">{t('orders.delivery_date')}</th>
                  <th className="w-130 align-right">{t('orders.total')}</th>
                  <th className="w-130 align-right">{t('orders.weight')}</th>
                  <th>{t('orders.note')}</th>
                </tr>
              </thead>
              <tbody>
                { this.renderRows() }
              </tbody>
            </HTMLTable>
          </div>
        </div>
        <OrderDetail user={this.props.user} align={true} axis={this.state.secondaryAxis} item={this.state.selected}/>
      </div>
      <OrderDialog isOpen={this.state.showDialog}  onClose={this.onDialogClose}/>
    </div>
  }
}

export default withEvents(Clients)