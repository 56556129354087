import React from 'react'
import logo from '../assets/logo.png'
import { Button, Card, FormGroup, InputGroup, ControlGroup } from '@blueprintjs/core'
import { axios, t } from '../common'
import User from '../models/user'
import Translator from '../i18n/translate'

interface LoginProps {
  onLogin: (user: User) => void
}

interface LoginState {
  email: string,
  password: string,
  error: boolean
}

export default class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props)
    this.state = {
      email: '',
      password: '',
      error: false
    }
  }

  async login() {
    if (this.state.email.length > 0 && this.state.password.length > 0) {
      try {
        const response = await axios.post('/auth/login', {
          email: this.state.email,
          password: this.state.password
        })

        if (response.data.token) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`
          const auth = (await axios.get('/auth/me')).data
          const user = new User(auth.user)
          Translator.setLang(user.lang)
          this.props.onLogin(user)
        } else {
          this.setState({ error: true })
        }
      } catch (e) {
        this.setState({ error: true })
      }
    }
  }

  update(event: any, field: string) {
    const state : any = {}
    state[field] = event.target.value
    this.setState(state)
  }

  renderError() {
    if (!this.state.error) {
      return null
    }

    return (<div className="row login-error">
      Invalid user details
    </div>)
  }

  render() {
    return <div className="login center-column">
      <div className="center-row">
        <Card className="login-box" elevation={4}>
          <div className="header">
            <img alt="logo" className="logo-gargo-top" src={logo}/>
          </div>
          <div className="login-content">
            <h3 style={{textAlign: 'center', marginTop: '50px', marginBottom: '30px'}}>{t('login.login')}</h3>
            <div className="column">
              <ControlGroup fill style={{width: '100%'}}>
                <FormGroup
                  label={t('login.email')}>
                  <InputGroup
                    placeholder={t('login.email')}
                    className="text-input-plain"
                    value={this.state.email}
                    onChange={(event: any) => this.update(event, 'email')}
                  />
                </FormGroup>
              </ControlGroup>

              <ControlGroup fill style={{width: '100%'}}>
                <FormGroup
                  label={t('login.password')}>
                  <InputGroup
                    placeholder={t('login.password')}
                    className="text-input-plain"
                    value={this.state.password}
                    onChange={(event: any) => this.update(event, 'password')}
                    type="password"
                  />
                </FormGroup>
              </ControlGroup>
            </div>

            { this.renderError() }
            <div className="row" style={{justifyContent: 'flex-end'}}>
              <Button small minimal onClick={() => { this.login() }} text={t('login.login_action')} icon="tick" intent="primary" />
            </div>
          </div>
        </Card>
      </div>
    </div>
  }
}