import React from 'react'
import { Button, Card, Colors, ProgressBar } from '@blueprintjs/core'
import SimpleOrders from '../components/simple_orders'
import moment from 'moment'
import { axios, formatDate, t } from '../common'
import User from '../models/user'

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts'

interface Props {
  user: User
}

interface State {
  data: any
  chartData: any
  chartWidth: number
  isLoading: boolean
}

export default class Dashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isLoading: false,
      data: {
        inProcessingCount: 0,
        inProcessingTotal: 0,
        inProcessingWeight: 0,
        deliveredCount: 0,
        deliveredTotal: 0,
        deliveredWeight: 0,
        invoicedCount: 0,
        invoicedTotal: 0,
        invoicedWeigh: 0,
        ordersPerDay: [],
        ordersValuePerDay: []
      },
      chartData: {
        orders: [
          {total: 234.50, createdAt: new Date(2019, 5, 1)},
          {total: 51.00, createdAt: new Date(2019, 5, 14)},
          {total: 174.10, createdAt: new Date(2019, 5, 20)},
          {total: 75.80, createdAt: new Date(2019, 5, 30)}
        ]
      },
      chartWidth: 450
    }
  }

  componentDidMount() {
    this.fetch()
  }

  async fetch() {
    await this.setState({ isLoading: true })
    const data = (await axios.get('/dashboard/user')).data
    await this.setState({ data, isLoading: false })
  }

  renderProgressBar() {
    return this.state.isLoading ? <div className="progress-bar" style={{width: '100%', height: '8px'}}>
      <ProgressBar
        value={1}
        intent="primary"
        animate={true}
        stripes={true}
      />
    </div> : null
  }

  renderHeroNumber(title: string, value: number, color: string, hasMargin = true) {
    return <div className={`column ${hasMargin ? 'm-r-20' : ''}`} style={{ flex: 1 }}>
      <Card style={{backgroundColor: `${color}`, color: 'white'}} elevation={1} className="w-100p m-10">
        <h5 className="hero-header">{ title }</h5>
        <h1 className="hero-header">{ value.toFixed(0) }</h1>
      </Card>
    </div>
  }
  render() {
    return <div>
      <h1 className="bp3-heading heading">
        <div className="row">
          <div className="column m-r-20">
            { t('pages.dashboard') }
          </div>
          <div className="column m-r-20">
            <Button icon="refresh" onClick={() => { this.fetch() }} className="m-t-4"/>
          </div>
          <div className="actions" style={{ flex: 1, marginTop: 0, paddingTop: 0 }}>
          </div>
        </div>
      </h1>
      { this.renderProgressBar() }
      <div className="row w-100p-20px" style={{ padding: '16px' }}>
        { this.renderHeroNumber(t('dashboard.orders_in_processing'), this.state.data.inProcessingCount, Colors.INDIGO2) }
        { this.renderHeroNumber(t('dashboard.orders_in_delivery'), this.state.data.deliveredCount, Colors.COBALT2) }
        { this.renderHeroNumber(t('dashboard.invoiced_orders'), this.state.data.invoicedCount, Colors.FOREST2) }
        { this.renderHeroNumber(t('dashboard.total_orders'), this.state.data.totalCount || 0, Colors.GOLD2, false) }
      </div>

      <div className="row w-100p-20px" style={{ padding: '16px' }}>
        <div className="column" style={{ width: 'calc(50% - 10px)' }}>
          <Card elevation={1} className="w-100p m-10">
            <h3 style={{marginTop: 0}}>{t('dashboard.orders_count_per_day_30_days')}</h3>
            <ResponsiveContainer height={200} width='100%'>
              <LineChart
                data={this.state.data.ordersPerDay}
                width={350}
                margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tickFormatter={timeStr => moment(timeStr).format('DD.MM.YYYY')}/>
                <YAxis dataKey="count"/>
                <Tooltip
                  formatter={(value, name) => [(value as number).toFixed(0), 'count' ]}
                  labelFormatter={(value) => formatDate(value as string) }
                />
                <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </div>

        <div className="column" style={{ width: 'calc(50% - 10px)', marginLeft: '20px' }}>
          <Card elevation={1} className="w-100p m-10">
            <h3 style={{marginTop: 0}}>{t('dashboard.orders_total_per_day_30_days')}</h3>
            <ResponsiveContainer height={200} width='100%'>
              <LineChart
                data={this.state.data.ordersValuePerDay}
                width={350}
                margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis name="date" dataKey="date" tickFormatter={timeStr => moment(timeStr).format('DD.MM.YYYY')}/>
                <YAxis name="count" dataKey="count"/>
                <Tooltip
                  formatter={(value, name) => [(value as number).toFixed(0), 'value'] }
                  labelFormatter={(value) => formatDate(value as string) }
                />
                <Line type="monotone" dataKey="count" stroke={Colors.GOLD1} activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </div>
      </div>

      <div className="row w-100p-20px" style={{ padding: '16px' }}>
        <div className="column m-r-20" style={{ flex: 1 }}>
          <Card elevation={2} className="w-100p m-10">
            <h3 style={{marginTop: 0}}>{t('dashboard.orders_in_processing')}</h3>
            <SimpleOrders user={this.props.user} params="state=processing"/>
          </Card>
        </div>
        <div className="column" style={{ flex: 1 }}>
          <Card elevation={2} className="w-100p m-10">
            <h3 style={{marginTop: 0}}>{t('dashboard.last_shipped_orders')}</h3>
            <SimpleOrders user={this.props.user} params="state=delivered&per_page=10"/>
          </Card>
        </div>
      </div>
    </div>
  }
}