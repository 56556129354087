import Category from './category'
import Product from './product'
import ProductGroup from './product_group'

export default class SalesOrderItem {
  code: string
  product: string
  quantity: number
  quantityString: string
  unit: string
  unit_price: number
  line_price: number
  category: Category
  productItem: Product
  productGroup: ProductGroup

  constructor(data: any) {
    this.code = data.code || ''
    this.product = data.product || ''
    this.quantity = data.quantity || 1
    this.quantityString = data.quantityString || '1'
    this.unit = data.unit || ''
    this.unit_price = data.unit_price || 0
    this.line_price = data.line_price || 0
    this.category = data.category ? new Category(data.category) : new Category({})
    this.productItem = data.productItem ? new Product(data.productItem) : new Product({})
    this.productGroup = data.productGroup ? new ProductGroup(data.productGroup) : new ProductGroup({})
  }
}