import React from 'react'
import { HTMLTable, InputGroup, Tag, Icon, ProgressBar } from '@blueprintjs/core'
import { EventBusProps, withEvents } from 'react-event-bus'
import { axios, t } from '../common'
import CustomerDialog from '../components/client_dialog'
import Customer from '../models/client';

interface CustomersProps {
  user: any
  EventBus: EventBusProps
}

interface CustomersState {
  customers: Array<Customer>
  selected: any
  showDialog: boolean
  query: string
  isLoading: boolean
}

class Customers extends React.Component<CustomersProps, CustomersState> {
  constructor(props : CustomersProps) {
    super(props)
    this.state = {
      customers: [],
      selected: false,
      showDialog: false,
      query: '',
      isLoading: false
    }
    this.onDialogClose = this.onDialogClose.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  componentDidMount () {
    this.fetch()
  }


  async fetch() {
    await this.setState({ isLoading: true })
    const query = encodeURI(this.state.query)
    const response = await axios.get(`/customers?query=${query}`)
    await this.setState({
      customers: response.data.customers.map((c: any) => new Customer(c))
    })
    await this.setState({ isLoading: false })
  }

  async onDialogClose (data: any, apply: boolean) {
    if (apply) {
      await this.setState({ isLoading: true })
      await axios.post('/customers', data)
      await this.fetch()
      await this.setState({ isLoading: false })
    }

    this.setState({ showDialog: false })
  }

  async onSelect (customer:any, event: MouseEvent) {
    await this.setState({
      selected: customer
    })
  }

  async onSave(data: any) {
    await this.setState({ isLoading: true })
    await axios.put(`/customers/${data.id}`, data)
    await this.fetch()
    await this.setState({ isLoading: false })
  }

  getEmailTag(email: string) {
    return email.length > 1 ?
      <Tag intent="primary" interactive round minimal icon="envelope" className="m-r-10"><a href={`mailto:${email}`}>{email}</a></Tag>
      : null
  }

  getPhoneTag(customer: Customer) {
    return customer.phone.length > 1 ?
      <Tag intent="success" interactive round minimal icon="mobile-phone" onClick={() => {}}>
        {customer.phone}
      </Tag>
      : null
  }

  getAddress(customer: Customer) {
    return [
      customer.address,
      customer.zipCode,
      customer.city
    ].filter(f => typeof f === 'string' && f.length > 0).join(', ')
  }

  renderProgressBar() {
    return this.state.isLoading ? <div className="progress-bar" style={{width: '100%', height: '8px'}}>
      <ProgressBar
        value={1}
        intent="primary"
        animate={true}
        stripes={true}
      />
    </div> : null
  }

  renderRows () {
    return this.state.customers.map((customer, index) => (
      <tr
        className={ this.state.selected && this.state.selected.id === customer.id ? 'row-selected' : ''}
        onClick={(event: any) => { this.onSelect(customer, event) }}
        key={`customers-row-${index}`}>
        <td>{ customer.name }</td>
        <td>
          { this.getEmailTag(customer.email) }
          { this.getPhoneTag(customer) }
        </td>
        <td>{ this.getAddress(customer) }</td>
        <td>{ customer.note }</td>
      </tr>
    ))
  }

  render () {
    return <div className="customers-page column">
      <h1 className="bp3-heading heading">
        <div className="row">
          <div className="column m-r-20">
            {t('pages.customers')}
          </div>
          <div className="column m-r-20">
            <InputGroup
              rightElement={<Icon className="filter-decor" icon="search"/>}
              placeholder={t('search')}
              onChange={async (event: any) => {
                await this.setState({ query: event.target.value })
                await this.fetch()
              }}
              value={this.state.query}
            />
          </div>
        </div>
      </h1>
      { this.renderProgressBar() }
      <div className="column-wide">
        <div className="customer-table">
          <HTMLTable condensed interactive className="table-wide">
            <thead>
              <tr>
                <th>Name</th>
                <th>Contact</th>
                <th>Adress</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              { this.renderRows() }
            </tbody>
          </HTMLTable>
        </div>
      </div>
      <CustomerDialog isOpen={this.state.showDialog} onClose={this.onDialogClose}/>
    </div>
  }
}

export default withEvents(Customers)