import React, { Component } from 'react'
import { Button, FormGroup, InputGroup, TextArea, Tag, Colors } from '@blueprintjs/core'
import SalesOrderItem from '../models/sales_order_item'
import { t } from '../common'

interface Props {
  readonly needsSplit: boolean
  readonly items: SalesOrderItem[]
  onAddItem: () => void
  onSave: (note: string, reference: string) => void
  onClose: () => void
}

interface State {
  readonly note: string
  readonly reference: string
}

export default class OrderDialogFooter extends Component<Props, State> {
  state = {
    note: '',
    reference: ''
  }

  getSummary() {
    return this.props.items
      .reduce((accu, item) => accu += item.line_price, 0)
      .toFixed(2)
  }

  hasValidItems() {
    let valid = true
    this.props.items.forEach(item => {
      if (!valid) return

      if (item.quantity % item.productItem.default_amount) {
        valid = false
      }
    })
    return valid
  }

  renderSplitInfo() {
    if (!this.props.needsSplit) {
      return null
    }

    return <Tag style={{backgroundColor: '#ffc2b3', color: 'black', fontWeight: 'normal'}}  >
      {t('orders.logistic_split')}
    </Tag>
  }

  render() {
    return <tfoot>
      <tr>
        <th colSpan={4}>
          <Button
            text={t('add_item')}
            minimal={true}
            className="m-r-20"
            icon="plus"
            onClick={() => this.props.onAddItem() }
          />
        </th>
        <th style={{textAlign: 'right', paddingRight: '20px', paddingTop: '12px'}}>{t('orders.total')}</th>
        <th style={{textAlign: 'right', paddingRight: '20px', paddingTop: '12px'}}>
          { this.getSummary() } €
        </th>
      </tr>

      <tr>
        <th colSpan={4} style={{borderTop: `1px solid ${Colors.LIGHT_GRAY1}`, paddingTop: '10px'}}>
          <FormGroup label={t('orders.note')} className="w-100p">
            <TextArea
              className="w-100p"
              value={this.state.note}
              onChange={(event: any) => { this.setState({ note: event.target.value }) }}
            />
          </FormGroup>
        </th>
        <th colSpan={2} style={{borderTop: `1px solid ${Colors.LIGHT_GRAY1}`, paddingTop: '10px'}}>
        <FormGroup label={t('orders.reference')} className="w-100p">
          <InputGroup
            className="w-100p"
            value={this.state.reference}
            onChange={(event: any) => { this.setState({ reference: event.target.value }) }}
          />
        </FormGroup>
        </th>
      </tr>
      <tr>
        <th colSpan={3}>
          <Tag style={{backgroundColor: '#FEE3A4', color: 'black', fontWeight: 'normal'}}>
            {t('orders.orange_marked')}
          </Tag>
          { this.renderSplitInfo() }
        </th>
        <th colSpan={3} style={{textAlign: 'right'}}>
          <Button text={t('close')} icon="cross" className="m-r-20" onClick={() => this.props.onClose() } />
          <Button disabled={!this.hasValidItems()} text={t('orders.create')} intent="success" icon="tick" onClick={() =>
            this.props.onSave(this.state.note, this.state.reference) }
          />
        </th>
      </tr>
    </tfoot>
  }
}
