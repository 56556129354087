import BaseModel from './base_model'

export default class Client extends BaseModel {
  name: string
  address: string
  zipCode: string
  city: string
  company: string
  email: string
  phone: string
  note: string
  number: string

  constructor(data: any) {
    super(data.id)
    this.name = data.name || ''
    this.address = data.address || ''
    this.zipCode = data.zipCode || ''
    this.city = data.city || ''
    this.company = data.company || ''
    this.email = data.email || ''
    this.phone = data.phone || ''
    this.note = data.note || ''
    this.number = data.number || ''
  }

}