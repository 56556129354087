import React from 'react'
import { HTMLTable } from '@blueprintjs/core'
import { axios, formatDateTime, t } from '../common'

interface JobsProps {
  job: any
}

interface JobsState {
  jobs: Array<any>,
  selected: any
}

export default class Jobs extends React.Component<JobsProps, JobsState> {
  constructor(props : JobsProps) {
    super(props)
    this.state = {
      jobs: [],
      selected: false
    }
  }

  componentDidMount () {
    this.fetch()
  }

  async fetch() {
    const response = await axios.get('/jobs')
    await this.setState({
      jobs: response.data.jobs
    })
  }

  async onEdit (job: any, event: MouseEvent) {
    await this.setState({
      selected: job
    })
  }


  async addJob() {
    await this.setState({
      selected: {}
    })
  }

  renderRows () {
    return this.state.jobs.map((job, index) => (
      <tr key={`jobs-row-${index}`}>
        <td>{ job.identifier }</td>
        <td>{ job.cron }</td>
        <td>{ job.lastRun ? job.lastRun.state : '' }</td>
        <td>{ formatDateTime(job.nextInvocation) }</td>
        <td>{ job.lastRun ? formatDateTime(new Date(job.lastRun.startedAt)) : '' }</td>
        <td>{ job.lastDuration ? `${job.lastRun.duration}ms` : '' }</td>
      </tr>
    ))
  }

  render () {
    return <div className="jobs-page column">
      <h1 className="bp3-heading heading">
        <div className="row">
          <div className="column">
            {t('pages.jobs')}
          </div>
        </div>
      </h1>
      <HTMLTable condensed bordered interactive className="table-wide">
        <thead>
          <tr>
            <th>{t('jobs.identifier')}</th>
            <th className="w-90">{t('jobs.period')}</th>
            <th>{t('jobs.lastState')}</th>
            <th className="w-150">{t('jobs.nextInvocation')}</th>
            <th className="w-150">{t('jobs.lastRun')}</th>
            <th className="w-130">{t('jobs.lastDuration')}</th>
          </tr>
        </thead>
        <tbody>
          { this.renderRows() }
        </tbody>
      </HTMLTable>
    </div>
  }
}