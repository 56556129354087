import React from 'react'
import { Dialog, HTMLTable, ProgressBar } from '@blueprintjs/core'
import produce from 'immer'
import { axios, t } from '../common'
import { showMessage, showError } from './toaster'
import OrderDialogRow from './order_dialog_row'
import OrderDialogFooter from './order_dialog_footer'
import ProductGroup from '../models/product_group'
import Product from '../models/product'
import SalesOrderItem from '../models/sales_order_item'

interface Props {
  isOpen: boolean
  onClose: (data: any, apply: boolean) => void
}

interface State {
  query: string
  categoryQuery: string
  products: Array<Product>
  items: Array<SalesOrderItem>
  note: string
  reference: string
  categories: Array<ProductGroup>
  splitAlert: boolean
  isLoading: boolean
}

export default class OrderDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      query: '',
      categoryQuery: '',
      items: [ new SalesOrderItem({}) ],
      products: [],
      categories: [],
      note: '',
      splitAlert: false,
      reference: '',
      isLoading: false
    }
    this.addItem = this.addItem.bind(this)
    this.onItemChange = this.onItemChange.bind(this)
    this.onItemDelete = this.onItemDelete.bind(this)
    this.save = this.save.bind(this)
  }

  componentWillReceiveProps(props: Props) {
    if (this.props.isOpen !== props.isOpen) {
      this.reset()
    }
  }

  reset() {
    this.setState({
      query: '',
      categoryQuery: '',
      items: [ new SalesOrderItem({}) ],
      products: [],
      categories: []
    })
  }

  async save(note: string, reference: string) {
    await this.setState({ isLoading: true })
    const orders = []
    if (this.needsSplit()) {
      orders.push({
        items: this.state.items.filter(item => item.productItem.supplier_id === '50004'),
        reference,
        note,
        type: 0
      })
      const rest = {
        items: this.state.items.filter(item => item.productItem.supplier_id !== '50004'),
        reference,
        note,
        type: 1
      }
      if (rest.items.length > 0) {
        orders.push(rest)
      }
    } else {
      const hasHangon = this.state.items.filter(item => item.productItem.supplier_id === '50004').length > 0
      orders.push({
        items: this.state.items,
        reference,
        note,
        type: hasHangon ? 0 : 1
      })
    }

    try {
      const promises = orders.map(order => axios.post('/sales_orders', order))
      await Promise.all(promises)
      showMessage("Order(s) successfully created")
      this.props.onClose({}, false)
    } catch (e) {
      showError("There was an error while saving the sales order, please try again")
    }
    await this.setState({ isLoading: false })
  }

  addItem() {
    this.setState(produce(this.state, state => {
      state.items.push(new SalesOrderItem({}))
      state.categoryQuery = ''
      state.categories = []
    }))
  }

  needsSplit() {
    const special = this.state.items.filter(item => item.productItem.supplier_id === '50004')
    const other = this.state.items.filter(item => item.productItem.supplier_id !== '50004')

    if (special.length === 0) {
      return false
    }

    if (special.length > 0 && other.length === 0) {
      return false
    }

    return true
  }

  async onItemChange(item: SalesOrderItem, index: number) {
    await this.setState(produce(this.state, state => {
      state.items.splice(index, 1, item)
    }))
  }

  async onItemDelete(item: SalesOrderItem, index: number) {
    await this.setState(produce(this.state, state => {
      state.items.splice(index, 1)
    }))
  }

  renderProgressBar() {
    return this.state.isLoading ? <div className="progress-bar" style={{width: '100%', height: '8px'}}>
      <ProgressBar
        value={1}
        intent="primary"
        animate={true}
        stripes={true}
      />
    </div> : null
  }

  renderItems() {
    return <HTMLTable condensed interactive className="table-wide w-100p">
      <thead>
        <tr>
          <th>{t('orders.category')}</th>
          <th>{t('orders.product')}</th>
          <th>{t('orders.code')}</th>
          <th className="align-right">{t('orders.quantity')}</th>
          <th className="align-right">{t('orders.unit_price')}</th>
          <th className="align-right">{t('orders.line_price')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        { this.state.items.map((item, index) =>
          <OrderDialogRow
            key={`order-dialog-row-${index}`}
            item={item}
            index={index}
            onChange={this.onItemChange}
            onDelete={this.onItemDelete}
          />
        )}
      </tbody>
      <OrderDialogFooter
        needsSplit={this.needsSplit()}
        items={this.state.items}
        onAddItem={this.addItem}
        onSave={this.save}
        onClose={() => this.props.onClose(this.state.items, false)}
      />
    </HTMLTable>
  }

  render() {
    return <Dialog
      title={t('orders.create')}
      isOpen={this.props.isOpen}
      style={{width: '1080px'}}
      canOutsideClickClose={false}
      onClose={() => { this.props.onClose({ items: this.state.items }, false) }}>
        <div className="sales-order-dialog">
          { this.renderProgressBar()}
          { this.renderItems() }
        </div>
    </Dialog>
  }
}