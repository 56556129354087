import React from 'react'
import { Provider } from 'react-redux'

import './App.scss'
import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'

import Main from './Main'
import store from './store/store'

const App: React.FC = () => {
  return <Provider store={store}>
    <Main />
  </Provider>
}

export default App;
