const en = {
  search: 'Search',
  submit: 'Submit',
  cancel: 'Cancel',
  close: 'Close',
  add_item: 'Add Item',
  states: {
    entered: 'Entered',
    delivered: 'Delivered',
    invoiced: 'Invoiced',
    approved_for_collecting: 'Approved',
    received: 'Received'
  },
  dashboard: {
    orders_in_processing: 'Orders in processing',
    orders_in_delivery: 'Orders in delivery',
    invoiced_orders: 'Invoiced orders',
    last_shipped_orders: 'Last shipped orders'
  },
  login: {
    login: 'Login',
    email: 'Email',
    password: 'Password',
    login_action: 'Log in'
  },
  navbar: {
    logout: 'Logout'
  },
  pages: {
    dashboard: 'Dashboard',
    orders: 'Orders',
    customers: 'Customers',
    administration: 'Administration',
    users: 'Users',
    jobs: 'Jobs'
  },
  orders: {
    order: 'Order',
    new_order: 'New Order',
    order_number: 'Order Number',
    reference: 'Reference',
    state: 'State',
    created_at: 'Created At',
    delivery_date: 'Delivery Date',
    total: 'Total',
    weight: 'Weight',
    note: 'Note',
    code: 'Code',
    product: 'Product',
    quantity: 'Quantity',
    unit: 'Unit',
    unit_price: 'Unit Price',
    line_price: 'Line Price',
    ask_question: 'Ask Question',
    send: 'Send',
    must_be_multiple: 'Must be multiple of',
    create: 'Create Order',
    toggle_favourite: 'Toggle Favourite'
  },
  users: {
    customer: 'Customer',
    lastName: 'Last Name',
    firstName: 'First Name',
    email: 'Email',
    role: 'Role',
    manager: 'Manager',
    user: 'User',
    edit_user: 'Edit User Details',
  },
  jobs: {
    identifier: 'Identifier',
    period: 'Period',
    lastState: 'Last State',
    nextInvocation: 'Next Invocation',
    lastRun: 'Last Run',
    lastDuration: 'Last Duration'
  }
}

export default en