import BaseModel from './base_model'
import Customer from './client'
import User from './user'
import SalesOrderItem from './sales_order_item'
import { t } from '../common'

export enum SalesOrderState {
  Entered = -1,
  Received = 0,
  ApprovedForCollecting = 25,
  Delivered = 90,
  Invoiced = 120
}

export enum SalesOrderStateIntent {
  Entered = 'none',
  Received = 'primary',
  ApprovedForCollecting = 'warning',
  Delivered = 'success',
  Invoiced = 'success'
}

export default class SalesOrder extends BaseModel {
  customer: Customer
  user: User
  code: string
  note: string
  total: number
  weight: number
  state: SalesOrderState
  createdAt: Date
  updatedAt: Date
  deliveryDate?: Date
  items: Array<SalesOrderItem>
  salesOrder: any
  reference: string

  constructor(data: any) {
    super(data.id)
    this.createdAt = data.createdAt ? new Date(data.createdAt) : new Date()
    this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : new Date()
    this.user = data.user ? new User(data.user) : new User({})
    this.customer = data.customer ? new Customer(data.customer) : new Customer({})
    this.code = data.code || ''
    this.note = data.note || ''
    this.salesOrder = data.salesOrder || {}
    this.total = data.salesOrder ? parseFloat(data.salesOrder.Sales_order_totalsum) : 0
    this.weight = data.salesOrder ? parseFloat(data.salesOrder.Weight) : 0
    this.state = typeof data.state === 'undefined' ? SalesOrderState.Entered : data.state
    this.reference = data.reference || ''

    if (data.salesOrder) {
      this.deliveryDate = new Date(data.salesOrder.Sales_order_delivery_date)
      if (data.salesOrder.OrderRows && data.salesOrder.OrderRows.SalesOrderRow) {
        if (!Array.isArray(data.salesOrder.OrderRows.SalesOrderRow)) {
          this.salesOrder.OrderRows.SalesOrderRow = [ this.salesOrder.OrderRows.SalesOrderRow ]
        }
      }
    }

    this.items = data.items ? data.items.map((i: any) => new SalesOrderItem(i)) : []
  }

  static getStateIntent(state: SalesOrderState) {
    switch(state) {
      case SalesOrderState.Entered:
        return 'none'
      case SalesOrderState.Received:
        return 'primary'
      case SalesOrderState.ApprovedForCollecting:
        return 'approved for collecting'
      case SalesOrderState.Invoiced:
        return 'success'
      case SalesOrderState.Delivered:
        return 'success'
      default:
        return 'none'
    }
  }

  static getStateString(state: number) {
    switch(state) {
      case SalesOrderState.Entered:
        return t('states.entered')
      case SalesOrderState.Received:
        return t('states.received')
      case SalesOrderState.ApprovedForCollecting:
        return t('states.approved_for_collecting')
      case SalesOrderState.Invoiced:
        return t('states.invoiced')
      case SalesOrderState.Delivered:
        return t('states.delivered')
      default:
        return t('states.entered')
    }
  }
}