import React from 'react'
import { Button, HTMLTable, Tag, Popover, ProgressBar, PopoverInteractionKind } from '@blueprintjs/core'
import SalesOrder from '../models/sales_order'
import { axios, formatDateTime, t } from '../common'
import OrderDetail from './order_detail'
import User from '../models/user'

interface Props {
  params: string
  user: User
}

interface State {
  isLoading: boolean
  items: Array<SalesOrder>
}

export default class SimpleOrders extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isLoading: false,
      items: []
    }
  }

  componentDidMount() {
    this.fetch()
  }

  async fetch() {
    await this.setState({ isLoading: true })
    const response = await axios.get(`/sales_orders?${this.props.params}`)
    await this.setState({
      items: response.data.salesOrders.map((s: any) => new SalesOrder(s))
    })
    await this.setState({ isLoading: false })
  }

  getTotal(item: SalesOrder) {
    return item.salesOrder ? parseFloat(item.salesOrder.Sales_order_totalsum).toFixed(2) : 0
  }

  renderRows() {
    return this.state.items.map((item => <tr key={`simple-orders-item-${this.props.params}-${item.id}`}>
      <td>{item.salesOrder.Sales_order_number}</td>
      <td>{item.reference}</td>
      <td>{formatDateTime(item.createdAt)}</td>
      <td><Tag intent={(SalesOrder.getStateIntent(item.state) as any)}><strong>{ SalesOrder.getStateString(item.state).toUpperCase() }</strong></Tag></td>
      <td className="align-right">{ this.getTotal(item) } €</td>
      <td>
        <Popover interactionKind={PopoverInteractionKind.HOVER}>
          <Button minimal={true} intent="primary" icon="eye-open"/>
          <OrderDetail user={this.props.user} align={false} axis='row' item={item}></OrderDetail>
        </Popover>
      </td>
    </tr>))
  }

  renderProgressBar() {
    return this.state.isLoading ? <div className="progress-bar" style={{width: '100%', height: '8px'}}>
      <ProgressBar
        value={1}
        intent="primary"
        animate={true}
        stripes={true}
      />
    </div> : null
  }

  render() {
    return <div className="simple-orders">
      { this.renderProgressBar() }
      <HTMLTable className="table-wide">
        <thead>
          <tr>
            <th>{t('orders.code')}</th>
            <th>{t('orders.reference')}</th>
            <th>{t('orders.created_at')}</th>
            <th>{t('orders.state')}</th>
            <th style={{textAlign: 'right'}}>{t('orders.total')}</th>
            <th>{t('orders.details')}</th>
          </tr>
        </thead>
        <tbody>
          { this.renderRows() }
        </tbody>
      </HTMLTable>
    </div>
  }
}