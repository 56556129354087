import Axios from 'axios'
import { BusEvent } from 'react-event-bus'
import { baseURL } from './settings'
import moment from 'moment'
import Client from './models/client'
import Translator from './i18n/translate'

export const DEFAULT_HOUR = 4
export const DAY_MS = 1000 * 3600 * 24

export const axios = Axios.create({
  baseURL,
  timeout: 60000
})

export const DateFormat = 'DD.MM.YYYY'
export const DateTimeFormat = 'DD.MM.YYYY HH:mm'

export class SmsEvent implements BusEvent {
  type: string = 'sms'
  value: any = true
  number: string = ''
  client: Client = new Client({})
}

export class SmsUpdateEvent implements BusEvent {
  type: string = 'sms_update'
  value: any = true
}

export const t = Translator.translate

export const today = () => {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), DEFAULT_HOUR, 0)
}

export const yesterday = () => {
  const now = today()
  return new Date(now.getTime() - DAY_MS)
}

export const tommorow = () => {
  const now = today()
  return new Date(now.getTime() + DAY_MS)
}

export const formatDate = (date: string | Date) => {
  return moment(date).format(DateFormat)
}

export const formatDateTime = (date: string | Date) => {
  return moment(date).format(DateTimeFormat)
}

export const parseDate = (date: string | Date) => {
  return moment(date, DateFormat).toDate()
}

export const formatNumber = (valueString: string, valueNumber: number) => {
  if (isNaN(valueNumber)) {
    valueString = '0'
  }

  let value = valueString
  let decimalGuard = valueString.match(/\./g)
  if (decimalGuard && decimalGuard.length > 1) {
    const split = valueString.split('.')
    value = `${split[0]}.${split[1]}`
  }

  let endsWithDecimal = value[value.length - 1] === '.'

  if (value.length === 2 && value[0] === '0' && !endsWithDecimal) {
    value = value[1]
  }

  if (endsWithDecimal) {
    if (value.length <= 1) {
      return '0'
    }
    return value
  } else {
    if (value.length === 0) {
      return ''
    }
    return value
  }
}