import React from 'react'
import { withRouter } from 'react-router-dom'
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core'
import { t } from '../common'

import '../styles/sidebar.scss'

const Sidebar = withRouter(({ history }) => (
  <div className="sidebar">
    <Menu className="transparent">
      <MenuItem
        active={window.location.pathname === '/dashboard'}
        onClick={() => { history.push('/dashboard') }}
        icon="tick-circle"
        text={t('pages.dashboard')}
      />

      <MenuItem
        active={window.location.pathname === '/sales_orders'}
        onClick={() => { history.push('/sales_orders') }}
        icon="list"
        text={t('pages.orders')}
      />

      <MenuItem
        active={window.location.pathname === '/customers'}
        onClick={() => { history.push('/customers') }}
        icon="people"
        text={t('pages.customers')}
      />

      <MenuDivider title={t('pages.administration')}/>

      <MenuItem
        active={window.location.pathname === '/users'}
        onClick={() => { history.push('/users') }}
        icon="chart"
        text={t('pages.users')}
      />

      <MenuItem
        active={window.location.pathname === '/jobs'}
        onClick={() => { history.push('/jobs') }}
        icon="chart"
        text={t('pages.jobs')}
      />

    </Menu>
  </div>
))

export default Sidebar