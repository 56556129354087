import objectPath from 'object-path'
import en from './en'
import fi from './fi'

export class Translator {
  dicts: any
  lang: string

  constructor() {
    this.lang = 'fi'
    this.dicts = { en, fi }
    this.translate = this.translate.bind(this)
  }

  setLang(lang: string) {
    this.lang = lang
  }

  translate(path: string, lang: string = this.lang) {
    return objectPath.get(this.dicts[lang], path, objectPath.get(this.dicts['fi'], path))
  }
}

const instance = new Translator()
export default instance