import React from 'react'
import { HTMLTable, Button, ProgressBar } from '@blueprintjs/core'
import UserDialog from '../components/user_dialog'
import { axios, t } from '../common'

interface UsersProps {
  user: any
}

interface UsersState {
  users: Array<any>
  selected: any
  showDialog: boolean
  isLoading: boolean

}

export default class Users extends React.Component<UsersProps, UsersState> {
  constructor(props : UsersProps) {
    super(props)
    this.state = {
      users: [],
      selected: false,
      showDialog: false,
      isLoading: false
    }
    this.onDialogClose = this.onDialogClose.bind(this)
  }

  componentDidMount () {
    this.fetch()
  }

  async fetch() {
    await this.setState({ isLoading: true })
    const response = await axios.get('/users')
    await this.setState({
      isLoading: false,
      users: response.data.users
    })
  }

  async onDialogClose (data: any, apply: boolean) {
    if (apply) {
      const existing = this.state.users.find(u => u.id === data.id)
      if (existing) {
        existing.firstName = data.firstName.trim()
        existing.lastName = data.lastName.trim()
        existing.name = `${existing.firstName} ${existing.lastName}`
        existing.email = data.email

        if (data.password && data.password.length > 0) {
          existing.password = data.password
        }
      } else {
        this.state.users.push(data)
      }

      if (data.id && data.id.length > 0) {
        await axios.put(`/users/${data.id}`, data)
      } else {
        await axios.post('/users', data)
      }
    }

    await this.setState({
      showDialog: false
    })

    this.fetch()
  }

  async onEdit (user: any, event: MouseEvent) {
    await this.setState({
      selected: user,
      showDialog: true
    })
  }

  async onDelete(user: any, event: any) {
    await axios.delete(`/users/${user.id}`)
    await this.fetch()
  }

  async addUser() {
    await this.setState({
      selected: {},
      showDialog: true
    })
  }

  renderRows () {
    return this.state.users.map((user, index) => (
      <tr key={`users-row-${index}`}>
        <td>{ user.customer ? user.customer.Customer_name1 : 'Unassigned' }</td>
        <td>{ user.lastName }</td>
        <td>{ user.firstName }</td>
        <td>{ user.email }</td>
        <td>{ user.role }</td>
        <td className="flex-align-right w-90">
          <Button onClick={(event: any) => { this.onEdit(user, event) }} icon="edit" className="m-r-10" small/>
          <Button onClick={(event: any) => { this.onDelete(user, event) }}icon="delete" intent="danger" small/>
        </td>
      </tr>
    ))
  }

  renderProgressBar() {
    return this.state.isLoading ? <div className="progress-bar" style={{width: '100%', height: '8px'}}>
      <ProgressBar
        value={1}
        intent="primary"
        animate={true}
        stripes={true}
      />
    </div> : null
  }

  render () {
    return <div className="users-page column">
      <h1 className="bp3-heading heading">
        <div className="row">
          <div className="column">
          <div>{t('pages.users')}</div>
          </div>
          <div className="column actions">
            <Button onClick={(event: any) => this.addUser() } text={t('add_item')} icon="plus" intent="success" />
          </div>
        </div>
      </h1>
      { this.renderProgressBar() }
      <HTMLTable condensed bordered interactive className="table-wide">
        <thead>
          <tr>
            <th>{t('users.customer')}</th>
            <th>{t('users.lastName')}</th>
            <th>{t('users.firstName')}</th>
            <th>{t('users.email')}</th>
            <th>{t('users.role')}</th>
            <th className="flex-align-right w-90"></th>
          </tr>
        </thead>
        <tbody>
          { this.renderRows() }
        </tbody>
      </HTMLTable>
      <UserDialog
        user={this.state.selected}
        isOpen={this.state.showDialog}
        onClose={this.onDialogClose}
      />
    </div>
  }
}