import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Navbar, Alignment, Colors, Tooltip, Position } from '@blueprintjs/core'
import { withEvents, EventBusProps } from 'react-event-bus'
import { t } from '../common'
import logo from '../assets/logo_white.png'
import '../styles/sidebar.scss'

interface NavBarProps {
  user: any
  onLogout: () => void
  EventBus: EventBusProps
}

interface NavBarState {
}


class NavBar extends React.Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props)
    this.state = {
    }
  }

  renderActions() {
    return this.props.user.role === 'user' ?
      <div className="user-actions" style={{marginRight: '60px'}}>
        <Link to="/dashboard">
          <Button
            className="white"
            minimal={true}
            color={Colors.WHITE}
            icon="dashboard"
            text={t('pages.dashboard')}
          />
        </Link>
        <Link to="/sales_orders">
          <Button
            className="white"
            minimal={true}
            color={Colors.WHITE}
            icon="bank-account"
            text={t('pages.orders')}
          />
        </Link>
      </div>
      :
      null
  }

  render() {
    return <Navbar
      className="navbar"
      fixedToTop={true}>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>
          <div className="row">
            <img alt="logo" className="logo-gargo-top" style={{height: '36px'}}src={logo}/>
          </div>
        </Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        { this.renderActions() }
        <Tooltip content="Logout" position={Position.BOTTOM}>
          <Button
            className="white"
            minimal={true}
            color={Colors.WHITE}
            icon="user"
            text={`${this.props.user.firstName} ${this.props.user.lastName}`}
            onClick={(event: any) => { this.props.onLogout() }}
          />
        </Tooltip>
      </Navbar.Group>
    </Navbar>
  }
}

export default withEvents(NavBar)
