import React from 'react'
import { Button, Dialog, FormGroup, InputGroup } from '@blueprintjs/core'

interface ClientDialogProps {
  isOpen: boolean,
  onClose: (data: any, apply: boolean) => void
}

interface ClientDialogState {
  form: any
}

export default class ClientDialog extends React.Component<ClientDialogProps, ClientDialogState> {
  constructor (props: ClientDialogProps) {
    super(props)
    this.state = {
      form: {}
    }
  }

  update (event: any, field: string) {
    const form = this.state.form
    form[field] = event.target.value
    this.setState({ form })
  }

  render () {
    return <Dialog
      isOpen={this.props.isOpen}
      title="Nový klient"
      onClose={() => { this.props.onClose(this.state.form, false) }}>
      <div className="edit-dialog">
        <div className="row">
          <FormGroup
            label="Meno">
            <InputGroup
              placeholder="Meno"
              className="text-input-plain m-r-20"
              value={this.state.form.firstName}
              onChange={(event: any) => this.update(event, 'firstName')}
            />
          </FormGroup>

          <FormGroup
            label="Priezvisko">
            <InputGroup
              placeholder="Priezvisko"
              className="text-input-plain m-r-20"
              value={this.state.form.lastName}
              onChange={(event: any) => this.update(event, 'lastName')}
            />
          </FormGroup>

          <FormGroup
            label="Spoločnosť">
            <InputGroup
              placeholder="Spoločnosť"
              className="text-input-plain m-r-20"
              value={this.state.form.company}
              onChange={(event: any) => this.update(event, 'company')}
            />
          </FormGroup>
        </div>

        <div className="row">
          <FormGroup
            label="Adresa">
            <InputGroup
              placeholder="Adresa"
              className="text-input-plain m-r-20"
              value={this.state.form.address}
              onChange={(event: any) => this.update(event, 'address')}
            />
          </FormGroup>

          <FormGroup
            label="Mesto">
            <InputGroup
              placeholder="Mesto"
              className="text-input-plain m-r-20"
              value={this.state.form.city}
              onChange={(event: any) => this.update(event, 'city')}
            />
          </FormGroup>

          <FormGroup
            label="PSČ">
            <InputGroup
              placeholder="PSČ"
              className="text-input-plain m-r-20"
              value={this.state.form.zipCode}
              onChange={(event: any) => this.update(event, 'zipCode')}
            />
          </FormGroup>
        </div>

        <div className="row">
          <FormGroup
            label="Telefón">
            <InputGroup
              placeholder="Telefón"
              className="text-input-plain m-r-20"
              value={this.state.form.phone}
              onChange={(event: any) => this.update(event, 'phone')}
            />
          </FormGroup>

          <FormGroup
            label="Email">
            <InputGroup
              placeholder="Email"
              className="text-input-plain m-r-20"
              value={this.state.form.email}
              onChange={(event: any) => this.update(event, 'email')}
            />
          </FormGroup>
        </div>

        <div className="row">
          <Button
            text="Potvrdiť"
            icon="tick"
            intent="primary"
            className="m-r-10"
            onClick={() => { this.props.onClose(this.state.form, true) }}
          />

          <Button
            text="Zrušiť"
            icon="cross"
            onClick={() => { this.props.onClose(this.state.form, false) }}
          />
        </div>

      </div>
    </Dialog>
  }
}