export default {
  search: 'Etsi',
  submit: 'Lähetä',
  cancel: 'Peruuta',
  close: 'Sulje',
  add_item: 'Lisää rivi',
  states: {
    entered: 'Vastaanotettu',
    delivered: 'Toimitettu',
    invoiced: 'Laskutettu',
    approved_for_collecting: 'Keräyslupa',
    received: 'Kirjattu'
  },
  dashboard: {
    orders_in_processing: 'Tilauksia käsittelyssä',
    orders_in_delivery: 'Toimitetut tilaukset',
    invoiced_orders: 'Laskutetut tilaukset',
    total_orders: 'Tilauksia yhteensä',
    last_shipped_orders: 'Viimeisimmät toimitetut tilaukset',
    orders_count_per_day_30_days: 'Tilauksia per päivä / edelliset 30 päivää',
    orders_total_per_day_30_days: 'Tilauksien arvo per päivä / edelliset 30 päivää',
  },
  login: {
    login: 'Kirjautuminen',
    email: 'Sähköposti',
    password: 'Salasana',
    login_action: 'Kirjaudu'
  },
  navbar: {
    logout: 'Kirjaudu ulos'
  },
  pages: {
    dashboard: 'Päänäkymä',
    orders: 'Tilaukset',
    customers: 'Asiakkaat',
    administration: 'Hallinta',
    users: 'Käyttäjät',
    jobs: 'Työt'
  },
  orders: {
    category: 'Kategoria',
    order: 'Tilaus',
    create: 'Luo tilaus',
    orange_marked: 'Oranssilla merkityt tuotteet ovat tilaustuotteita',
    logistic_split: 'Tilaus jaetaan kahtia logistisista syistä',
    new_order: 'Uusi tilaus',
    order_number: 'Tilausnumero',
    reference: 'Tilausnumeronne',
    state: 'Tila',
    created_at: 'Luotu',
    updated_at: 'Päivitetty',
    delivery_date: 'Toimituspäivä',
    total: 'Yhteensä',
    weight: 'Paino',
    note: 'Kuvaus',
    code: 'Koodi',
    product: 'Tuote',
    quantity: 'Määrä',
    unit: 'Yksikkö',
    unit_price: 'Yksikköhinta',
    line_price: 'Rivin hinta',
    details: 'Tarkemmat tiedot',
    please_wait_processing: "OLE HYVÄ JA ODOTA, TILAUSTA KÄSITELLÄÄN",
    select_order: 'Valitse tilaus',
    ask_question: 'Viesti tilausta koskien',
    send: 'Send',
    must_be_multiple: 'Must be multiple of',
    toggle_favourite: 'Merkkaa suosikiksi'
  },
  users: {
    customer: 'Asiakas',
    lastName: 'Sukunimi',
    firstName: 'Etunimi',
    email: 'Sähköposti',
    role: 'Rooli',
    manager: 'Ylläpitäjä',
    user: 'Käyttäjä',
    edit_user: 'Muokkaa käyttäjän tietoja',
  },
  jobs: {
    identifier: 'Tunnus',
    period: 'Aikaväli',
    lastState: 'Viimeisin tila',
    nextInvocation: 'Seuraava ajo',
    lastRun: 'Viimeisin ajo',
    lastDuration: 'Viimeisin kesto'
  }

}