import React, { Component } from 'react'
import { Button, ControlGroup, FormGroup, InputGroup, Text, Tooltip, MenuItem, NumericInput, Intent, Checkbox, PopoverPosition } from '@blueprintjs/core'
import { Suggest } from '@blueprintjs/select'
import produce from 'immer'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { axios, formatNumber, t } from '../common'
import ProductGroup from '../models/product_group'
import Product from '../models/product'
import SalesOrderItem from '../models/sales_order_item'

const CategorySelect = Suggest.ofType<ProductGroup>()
const ProductSelect = Suggest.ofType<Product>()

const euro = <Text className="input-euro-symbol">&euro;</Text>
const DEBOUNCE_RATE = 300

interface Props {
  readonly item: SalesOrderItem
  readonly index: number
  onChange: (item: SalesOrderItem, index: number) => void
  onDelete: (item: SalesOrderItem, index: number) => void
}

interface State {
  readonly form: SalesOrderItem
  readonly query: string
  readonly categoryQuery: string
  readonly products: Array<Product>
  readonly categories: Array<ProductGroup>
}

export default class OrderDialogRow extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      form: props.item,
      query: '',
      categoryQuery: '',
      products: [],
      categories: []
    }
    this.getProductIntent = this.getProductIntent.bind(this)
    this.productRenderer = this.productRenderer.bind(this)
    this.onProductSelect = this.onProductSelect.bind(this)
    this.onCategorySelect = this.onCategorySelect.bind(this)
    this.updateQuantity = this.updateQuantity.bind(this)
    this.onCategoryQueryChange = AwesomeDebouncePromise(this.onCategoryQueryChange.bind(this), DEBOUNCE_RATE)
    this.onProductQueryChange = AwesomeDebouncePromise(this.onProductQueryChange.bind(this), DEBOUNCE_RATE)
  }

  getProductIntent(product: Product) {
    if (!product.exists) {
      return 'success'
    }

    let intent = 'warning'
    if (product.supplier.name !== 'HANGON AB') {
      const stock = product.stocks.find((stock: any) => stock.stock === '1')
      if (stock && stock.free > 0) intent = 'success'
    } else {
      intent = 'success'
    }

    return intent
  }

  async setFavourite(product: Product, checked: boolean) {
    product.is_favourite = !product.is_favourite
    this.setState({ products: this.state.products })
    await axios.get(`/products/${product.id}/favourite?enabled=${checked}`)
  }

  productRenderer(product: Product, options: any) {
    let suffix = ' (ei varastossa)'
    if (product.supplier.name === 'HANGON AB') {
      suffix = ' (Ruotsin varastossa)'
    } else {
      const stock = product.stocks.find((stock: any) => stock.stock === '1')
      if (stock && stock.free > 0) suffix = ' (varastossa)'
    }

    let className = "order-row-"
    return <MenuItem
      key={`product-item-${product.id}`}
      onClick={(evt: any) => {
        if (evt.target.className !== 'bp3-control-indicator') {
          options.handleClick(evt)
        } else {
          evt.stopPropagation()
        }
      }}
      text={`${product.name} ${suffix}`}
      labelElement={<div style={{ display: 'flex', flexDirection: 'row'}}>
        <div style={{ flex: 1, marginRight: 12 }}>{ product.code }</div>
        <div style={{ width: 24, margin: 0, padding: 0, justifyContent: 'flex-end' }}>
          <Tooltip
            boundary="viewport"
            content={t('orders.toggle_favourite')}
            position={PopoverPosition.RIGHT}>
            <Checkbox
              checked={product.is_favourite}
              labelElement={null}
              onClick={(evt) => {
                evt.stopPropagation()
                evt.preventDefault()
                this.setFavourite(product, evt.currentTarget.checked)
              }}
            />
          </Tooltip>
        </div>
      </div>}
      multiline={true}
      intent={this.getProductIntent(product) === 'warning' ? Intent.WARNING : Intent.SUCCESS}
      textClassName={className}
    />
  }

  productValueRenderer(product: Product) {
    return product.name
  }

  categoryRenderer(category: ProductGroup, options: any) {
    return <MenuItem
      key={`category-item-${category.id}`}
      onClick={options.handleClick}
      text={category.name}
    />
  }

  categoryValueRenderer(category: ProductGroup) {
    return category.name
  }

  async onCategorySelect(category: ProductGroup) {
    const state = produce(this.state, state => {
      state.form.category = category
      state.query = ''
    })
    await this.setState(state)
    this.onProductQueryChange('')
    this.props.onChange(this.state.form, this.props.index)
  }

  async onProductSelect(product: Product) {
    const group = await axios.get(`/product_groups/${product.group_id}`)
    const state = produce(this.state, state => {
      const item = state.form
      item.productItem = product
      item.code = product.code
      item.unit = product.unit
      item.unit_price = product.price
      item.quantity = item.productItem.default_amount
      item.quantityString = `${item.quantity}`
      item.productGroup = new ProductGroup(group.data)

      if (item.unit_price) {
        if (item.productItem.price_per > 0) {
          item.line_price = (item.quantity / item.productItem.price_per) * item.productItem.price
        } else {
          item.line_price = item.quantity * item.productItem.price
        }
      }

      state.categoryQuery = item.productGroup.name
    })

    await this.setState(state)
    this.props.onChange(this.state.form, this.props.index)
  }

  async onCategoryQueryChange(query: string) {
    const response = await axios.get(`/product_groups?query=${encodeURI(query)}`)
    const categories = response.data.groups.map((g: any) => new ProductGroup(g))
    await this.setState({ categories })
  }

  async onProductQueryChange(query: string) {
    const params = [`query=${encodeURI(query)}`]
    if (this.state.form.category.exists) {
      params.push(`group_id=${this.state.form.category.id}`)
    }

    const response = await axios.get(`/products?${params.join('&')}`)
    const products = response.data.products.map((p: any) => new Product(p))
    await this.setState({ products })
  }

  async updateQuantity(valueNumber: number, valueString: string) {
    const value = formatNumber(valueString, valueNumber)
    await this.setState(produce(this.state, state => {
      const item = state.form
      item.quantity = parseFloat(value)
      item.quantityString = value
      if (item.unit_price) {
        if (item.productItem.price_per > 0) {
          item.line_price = (item.quantity / item.productItem.price_per) * item.productItem.price
        } else {
          item.line_price = item.quantity * item.productItem.price
        }
      }
    }))
    this.props.onChange(this.state.form, this.props.index)
  }

  render() {
    return <tr className={`order-row-${this.getProductIntent(this.state.form.productItem)}` } key={`order_dialog_item_${this.props.index}`}>
      <td className="w-200">
        <CategorySelect
          query={this.state.categoryQuery}
          items={this.state.categories}
          itemRenderer={this.categoryRenderer}
          activeItem={this.state.form.productGroup}
          onQueryChange={this.onCategoryQueryChange}
          inputValueRenderer={this.categoryValueRenderer}
          onItemSelect={this.onCategorySelect}
          inputProps={{placeholder: t('search')}}
          className="w-200">
          <Button text={this.state.form.productGroup.id ? this.state.form.productGroup.name : 'Select Category'} rightIcon="double-caret-vertical" className="m-t-4" />
        </CategorySelect>
      </td>
      <td className="w-260">
        <ProductSelect
          query={this.state.query}
          items={this.state.products}
          itemRenderer={this.productRenderer}
          activeItem={this.state.form.productItem}
          onQueryChange={this.onProductQueryChange}
          inputValueRenderer={this.productValueRenderer}
          onItemSelect={this.onProductSelect}
          inputProps={{placeholder: t('search')}}
          noResults={<div>{t('search')}</div>}
          className="w-260">
          <Button text={this.state.form.product ? this.state.form.product : 'Select Product'} rightIcon="double-caret-vertical" className="m-t-4" />
        </ProductSelect>
      </td>
      <td>
        <ControlGroup fill style={{flex: 1}}>
          <InputGroup
            disabled={true}
            placeholder={t('orders.code')}
            className="text-input-plain"
            value={this.state.form.code}
          />
        </ControlGroup>
      </td>
      <td>
        <ControlGroup style={{flex: 1}}>
          <FormGroup
            style={{marginBottom: 0}}
            helperText={this.state.form.quantity % this.state.form.productItem.default_amount ? `${t('orders.must_be_multiple')} ${this.state.form.productItem.default_amount}` : ''}
            intent={this.state.form.quantity % this.state.form.productItem.default_amount ? 'danger' : 'success'}>
            <NumericInput
              name="quantity"
              intent={this.state.form.quantity % this.state.form.productItem.default_amount ? 'danger' : 'success'}
              allowNumericCharactersOnly={true}
              buttonPosition="right"
              placeholder={t('orders.quantity')}
              rightElement={<Text className="input-euro-symbol">{this.state.form.unit}</Text>}
              value={this.state.form.quantityString}
              stepSize={this.state.form.productItem.default_amount}
              majorStepSize={this.state.form.productItem.default_amount * 10}
              minorStepSize={null}
              style={{ width: 130 }}
              onValueChange={this.updateQuantity}
            />
          </FormGroup>
        </ControlGroup>
      </td>
      <td>
        <ControlGroup fill style={{flex: 1}}>
          <NumericInput
            disabled={true}
            name="unit_price"
            allowNumericCharactersOnly={true}
            buttonPosition="none"
            placeholder={t('orders.unit_price')}
            rightElement={euro}
            value={this.state.form.unit_price.toFixed(2)}
            onValueChange={this.updateQuantity}
            className="text-input-short"
          />
        </ControlGroup>
      </td>
      <td>
        <ControlGroup fill style={{flex: 1}}>
          <NumericInput
            disabled={true}
            name="line_price"
            allowNumericCharactersOnly={true}
            buttonPosition="none"
            placeholder={t('orders.line_price')}
            rightElement={euro}
            value={this.state.form.line_price.toFixed(2)}
            onValueChange={(valueNumber: number, valueString: string) => { }}
            className="text-input-short"
          />
        </ControlGroup>
      </td>
      <td>
        <Button
          minimal
          icon="trash"
          onClick={() => { this.props.onDelete(this.state.form, this.props.index) }}
          disabled={ this.props.index === 0 }
        />
      </td>
    </tr>
  }
}
