import { Position, Toaster } from "@blueprintjs/core"

/** Singleton toaster instance. Create separate instances for different options. */
const AppToaster = Toaster.create({
    className: "gargo-toaster",
    position: Position.TOP,
})

export default AppToaster

export const showMessage = (message: string) => {
    AppToaster.show({ message, intent: 'success' })
}

export const showError = (message: string) => {
    AppToaster.show({ message, intent: 'danger' })
}