import React from 'react'
import { Button, Dialog, FormGroup, InputGroup, MenuItem, ControlGroup } from '@blueprintjs/core'
import { Select, Suggest } from '@blueprintjs/select'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { axios, t } from '../common'
import Customer from '../models/client'
import User from '../models/user'

const CustomerSuggest = Suggest.ofType<Customer>()
const RoleSelect = Select.ofType<any>()

interface UserDialogProps {
  isOpen: boolean,
  user: User,
  onClose: (data: any, apply: boolean) => void
}

interface UserDialogState {
  customers: Array<Customer>,
  roles: any[],
  form: any,
  activeRole: any,
  activeCustomer?: Customer,
  customerQuery: string
}

const Roles = [
  { id: 'user', label: 'User'},
  { id: 'manager', label: 'Manager'}
]

export default class UserDialog extends React.Component<UserDialogProps, UserDialogState> {
  constructor (props: UserDialogProps) {
    super(props)
    this.state = {
      customers: [],
      roles: Roles,
      form: {},
      activeRole: props.user.role === 'manager' ?  Roles[1] : Roles[0],
      activeCustomer: props.user.customer ? props.user.customer : new Customer({}),
      customerQuery: props.user.customer ? props.user.customer.name : ''
    }
    this.onCustomerQueryChange = AwesomeDebouncePromise(this.onCustomerQueryChange.bind(this), 500)
  }

  componentWillReceiveProps (nextProps: UserDialogProps) {
    if (nextProps.user && nextProps.user.customer) {
      console.log('setting props')
      this.setState({
        activeRole: nextProps.user.role === 'manager' ? Roles[1] : Roles[0],
        activeCustomer: nextProps.user.customer,
        form: nextProps.user,
        customerQuery: nextProps.user.customer.name,
        customers: [ nextProps.user.customer ]
      })
    }
  }

  itemPredicate() {
    return true
  }

  roleRenderer(item: any, options: any) {
    return <MenuItem
      key={item.id}
      text={item.label}
      onClick={options.handleClick}
    />
  }

  customerRenderer(item: any, options: any) {
    return <MenuItem
      key={item.id}
      text={item.name}
      label={item.number}
      onClick={options.handleClick}
    />
  }

  async onCustomerQueryChange(customerQuery: string) {
    const response = await axios.get(`/customers?query=${encodeURI(customerQuery)}`)
    await this.setState({ customerQuery, customers: response.data.customers })
  }

  onRoleSelect(item: any) {
    const form = this.state.form
    form.role = item.id
    this.setState({ form, activeRole: item })
  }

  onCustomerSelect(customer: Customer) {
    const form = this.state.form
    form.customerNumber = customer.id
    this.setState({ form, activeCustomer: customer })
  }

  customerValueRenderer(customer: Customer) {
    return `${customer.name} (${customer.number})`
  }

  update (event: any, field: string) {
    const form = this.state.form
    form[field] = event.target.value
    this.setState({ form })
  }

  render () {
    (window as any).kiki = this
    return <Dialog
      isOpen={this.props.isOpen}
      title="Edit user details"
      onClose={() => { this.props.onClose(this.state.form, false) }}
    >
      <div className="edit-dialog">
        <div className="row">
          <ControlGroup fill style={{ flex: 1, marginRight: '20px' }}>
            <FormGroup
              label={t('users.firstName')}>
              <InputGroup
                placeholder={t('users.firstName')}
                className="text-input-plain-n-w"
                value={this.props.user.firstName}
                onChange={(event: any) => this.update(event, 'firstName')}
              />
            </FormGroup>
          </ControlGroup>

          <ControlGroup fill style={{ flex: 1 }}>
            <FormGroup
              label={t('users.lastName')}>
              <InputGroup
                placeholder={t('users.lastName')}
                className="text-input-plain-n-w"
                value={this.props.user.lastName}
                onChange={(event: any) => this.update(event, 'lastName')}
              />
            </FormGroup>
          </ControlGroup>
        </div>

        <div className="row">
          <ControlGroup fill style={{ flex: 1, marginRight: '20px' }}>
            <FormGroup
              label={t('users.email')}>
              <InputGroup
                placeholder={t('users.email')}
                className="text-input-plain-n-w m-r-20"
                value={this.props.user.email}
                onChange={(event: any) => this.update(event, 'email')}
              />
            </FormGroup>
          </ControlGroup>

          <ControlGroup fill style={{ flex: 1 }}>
            <FormGroup
              label={t('login.password')}>
              <InputGroup
                placeholder={t('login.password')}
                className="text-input-plain-n-w"
                value={this.props.user.password}
                type="password"
                onChange={(event: any) => this.update(event, 'password')}
              />
            </FormGroup>
          </ControlGroup>
        </div>

        <div className="row">
          <ControlGroup fill style={{ flex: 1, marginRight: '20px' }}>
            <FormGroup
              label={t('users.customer')}>
              <CustomerSuggest
                query={this.state.customerQuery}
                onQueryChange={this.onCustomerQueryChange}
                inputValueRenderer={this.customerValueRenderer}
                items={this.state.customers}
                itemRenderer={this.customerRenderer}
                activeItem={this.state.activeCustomer}
                className="text-input-plain-n-w"
                onItemSelect={(item: any) => { this.onCustomerSelect(item) }}>
                <Button style={{ width: '100%' }} text={
                  this.state.activeCustomer ? `${this.state.activeCustomer.name} (${this.state.activeCustomer.number})` : 'Choose customer'
                } rightIcon="double-caret-vertical" />
              </CustomerSuggest>
            </FormGroup>
          </ControlGroup>

          <ControlGroup fill style={{ flex: 1 }}>
            <FormGroup
              label={t('users.role')}>
              <RoleSelect
                items={this.state.roles}
                itemRenderer={this.roleRenderer}
                onItemSelect={(item: any) => { this.onRoleSelect(item) }}>
                <Button text={this.state.activeRole.label} rightIcon="double-caret-vertical" />
              </RoleSelect>
            </FormGroup>
          </ControlGroup>
        </div>

        <div className="row">
          <Button
            text={t('submit')}
            icon="tick"
            intent="primary"
            className="m-r-10"
            onClick={() => { this.props.onClose(this.state.form, true) }}
          />

          <Button
            text={t('close')}
            icon="cross"
            onClick={() => { this.props.onClose(this.state.form, false) }}
          />
        </div>

      </div>
    </Dialog>
  }
}