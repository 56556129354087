import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { EventProvider } from 'react-event-bus'
import NavBar from '../components/navbar'
import Sidebar from '../components/sidebar'
import Customers from '../pages/customers'
import Dashboard from '../pages/dashboard'
import SalesOrders from '../pages/sales_orders'
import Users from '../pages/users'
import Jobs from '../pages/jobs'
import User from '../models/user'

interface ManagerViewProps {
  user: User
  onLogout: () => void
}

interface State {
  redirectUser: boolean
}

export default class ManagerView extends React.Component<ManagerViewProps, State> {
  constructor(props: ManagerViewProps) {
    super(props)
    this.state = {
      redirectUser: this.shouldRedirectUser()
    }
  }

  shouldRedirectUser() {
    const allowedUser = ['#/sales_orders', '#/dashboard']
    if (this.props.user.isUser) {
      return !allowedUser.includes(window.location.hash)
    } else {
      return window.location.hash !== '#/dashboard'
    }
  }

  updateRedirectState() {
    const state = {
      redirectUser: this.shouldRedirectUser()
    }

    if (state.redirectUser !== this.state.redirectUser) {
      this.setState(state)
    }
  }

  renderSidebar() {
    return this.props.user.role === 'manager' ?
       <Sidebar />
      : null
  }

  componentWillReceiveProps(props: ManagerViewProps) {
    this.updateRedirectState()
  }

  componentDidMount() {
    this.updateRedirectState()
  }

  getUserRoutes() {
    return <div className="manager-page">
      <Route path="/dashboard" component={() => <Dashboard user={this.props.user}/>} />
      <Route path="/sales_orders" component={() => <SalesOrders user={this.props.user}/>} />
    </div>
  }

  getManagerRoutes() {
    return <div className="manager-page">
      <Route path="/dashboard" component={() => <Dashboard user={this.props.user}/>} />
      <Route path="/sales_orders" component={() => <SalesOrders user={this.props.user}/>} />
      <Route path="/customers" component={Customers} />
      <Route path="/users" component={Users} />
      <Route path="/jobs" component={Jobs} />
    </div>
  }

  renderRoutes() {
    if (this.props.user.isManager) {
      return this.getManagerRoutes()
    } else {
      return this.getUserRoutes()
    }
  }

  render () {
    if (this.state.redirectUser) {
      return <Redirect to={'/dashboard'} />
    }

    return <div className="gargo">
      <EventProvider>
        <NavBar onLogout={this.props.onLogout} user={this.props.user}/>
        <div className="content-manager">
          { this.renderSidebar() }
          { this.renderRoutes() }
        </div>
      </EventProvider>
    </div>
  }
}