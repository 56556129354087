import Customer from './client'

export default class User {
  id: string
  name: string
  firstName: string
  lastName: string
  email: string
  password: string
  role: string
  customer: Customer
  lang: string

  constructor(data: any) {
    this.id = data.id
    this.name = data.name || ''
    this.firstName = data.firstName || ''
    this.lastName = data.lastName || ''
    this.email = data.email || ''
    this.password = data.password || ''
    this.role = data.role || 'user'
    this.lang = data.lang || 'fi'
    this.customer = data.customer ? new Customer(data.customer) : new Customer({})
  }

  get isUser() {
    return this.role === 'user'
  }

  get isManager() {
    return this.role === 'manager'
  }

  get exists() {
    return this.id && this.id.length > 0
  }
}