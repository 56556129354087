import BaseModel from './base_model'

export default class Product extends BaseModel {
  name: string
  code: string
  price: number
  unit: string
  supplier: any
  group_id: string
  supplier_id: string
  stocks: any[]
  stock: number
  default_amount: number
  price_per: number
  is_favourite: boolean

  constructor(data: any) {
    super(data.id)
    this.name = data.name || ''
    this.code = data.code || ''
    this.price = data.price ? parseFloat(data.price) : 0
    this.supplier = data.supplier || {}
    this.supplier_id = data.supplier_id || ''
    this.unit = data.unit ? data.unit.unit : 'kpl'
    this.group_id = data.group_id || ''
    this.stocks = data.stocks || []
    const stock = this.stocks.find((s: any) => s.stock === '1')
    this.stock = stock ? stock.instock : 0
    this.default_amount = data.unit && data.unit.default_amount ? data.unit.default_amount : 1
    this.price_per = data.unit && data.unit.price_per ? data.unit.price_per : 0
    this.is_favourite = data.is_favourite || false
  }
}